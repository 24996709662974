import Map from "./components/Map";
import React, { Fragment, useState } from "react";
import "./components/styles/App.css";
import MobilePopup from "./components/MobilePopUp";
function App() {
  const [showPopUp, setShowPopUp] = useState(true)
  const isMobileDevice = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  };

  return (
    <div>
      <Map />
      {(isMobileDevice() && showPopUp) && <MobilePopup close={()=>setShowPopUp(false)}/>}
    </div>
  );
}

export default App;
