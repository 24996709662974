import * as React from "react";
import { useRef } from "react";
import { useState, Fragment } from "react";
import ReactMapGL, { Marker, Source, Layer } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "./styles/map.css";
import * as turf from "@turf/turf";
import * as d3 from "d3-ease";
import { api_credentials } from "../var.js";
import LoadingSpinner from "./LoadingSpinner";
import io from "socket.io-client";
import { motion } from "framer-motion";
import localStyle from "../assets/map/style.json";
import { americaRoute } from "../assets/routes/routes";
import { asiaAndEuropeRoute } from "../assets/routes/eu_route";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// const e80days = {
//   id: 0,
//   classic: "mapbox://styles/rcristea6868/cllaujyaq00s601pd1jcb91r8",
//   // classic: "mapbox://styles/ecogptest/clj5q5snj001w01pi8g9zh0z1",
//   satellite: "mapbox://styles/ecogptest/clj5qauwx001q01qu9gi5cf8k",
//   globe: "mapbox://styles/ecogptest/cljskykea01al01p57m2i5q92",
// };

const socket = io(api_credentials.socketURL, { transports: ["polling"] });

const routesCoordinates = {
  suaToHongKongCoordinates: {
    start: [-122.38268053116451, 47.25619759391209], // Sua coordinates
    end: [114.26034882813943, 22.568925470128907], // Hong Kong coordinates
  },
  moroccoToMiami: {
    start: [-122.38268053116451, 47.25619759391209], // Morocco coordinates
    end: [114.26034882813943, 22.568925470128907], // Miami coordinates
  },
};

const splitRoute = false; // set to true if you need to split the route(negative and positive longitude, e.g. America-Asia)

const defViews = {
  northAmerica: {
    latitude: 38.87558378709248,
    longitude: -100.82708644517623,
  },
  asia: {
    latitude: 37.93042133821786,
    longitude: 102.63651187325158,
  },
};

function Map() {
  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line no-useless-escape
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
  const TIME_BETWEEN_CENTER_FOLLOW = 5 * 60 * 1000; //1 min = 60.000ms
  const PHONE_SPONSOR_LINK =
    "https://www.chargeholidays.com/charge-holidays-app";
  const zoomDef = 3;
  const isMobile = window.mobileCheck();
  const defView = {
    width: window.innerWidth,
    height: window.innerHeight,
    latitude: defViews.asia.latitude,
    longitude: defViews.asia.longitude,
    zoom: zoomDef,
  };
  const zoomLevels = [
    [0, 78271.484],
    [1, 39135.742],
    [2, 19567.871],
    [3, 9783.936],
    [4, 4891.968],
    [5, 2445.984],
    [6, 1222.992],
    [7, 611.496],
    [8, 305.748],
    [9, 152.874],
    [10, 76.437],
    [11, 38.218],
    [12, 19.109],
    [13, 9.555],
    [14, 4.777],
    [15, 2.389],
    [16, 1.194],
    [17, 0.597],
    [18, 0.299],
    [19, 0.149],
    [20, 0.075],
    [21, 0.037],
    [22, 0.019],
  ];
  const kurykToBakuRoute = {
    type: "Feature",
    properties: {
      name: "Route from Kuryk to Baku",
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [51.441928183201725, 43.162370250393565],
        [51.44170389424028, 43.160734224309664],
        [51.44119924407832, 43.15954807800429],
        [51.44170389424028, 43.15828010304128],
        [51.441928183201725, 43.1573802338786],
        [51.44349820593044, 43.15660306348258],
        [51.444563578496, 43.15590769211829],
        [51.44641396242531, 43.15566226504134],
        [51.4480400573932, 43.15545774172469],
        [51.44820827411422, 43.15447602027501],
        [51.468698229272206, 43.09391506237847],
        [51.47343755776433, 43.07199250686196],
        [51.47659710498084, 43.0048514042997],
        [51.480191180315146, 42.9825366518609],
        [51.47798254221354, 42.962769012859326],
        [51.460799296453104, 42.92408388645566],
        [51.424464337968914, 42.88358240493437],
        [51.37087515805351, 42.82469929229214],
        [51.27814351574449, 42.67551584616878],
        [51.18541187343547, 42.52633240004542],
        [51.109545578193035, 42.409879959364545],
        [51.0336792829506, 42.29342751868367],
        [50.91888577428509, 42.140161676753564],
        [50.88690264238629, 42.00945685907526],
        [50.85491951048749, 41.87875204139695],
        [50.844258466712574, 41.715613415737806],
        [50.83359742293766, 41.55247479007866],
        [50.83892794492196, 41.39982863948181],
        [50.84425846690627, 41.24718248888496],
        [50.83892794492283, 41.13476941832609],
        [50.83359742293939, 41.022356347767214],
        [50.78118753333362, 40.80066971307437],
        [50.774961681126086, 40.62679871817927],
        [50.79095324707552, 40.44069781346457],
        [50.79095324707552, 40.25381955842548],
        [50.79367482871663, 40.18492675995925],
        [50.752456589721845, 40.12023001335005],
        [50.6736817634488, 40.06642379995077],
        [50.47295275395379, 40.08714677312108],
        [50.36808865917868, 40.10768274608702],
        [50.276040345421904, 40.151317836833215],
        [50.236578960839324, 40.204983577058584],
        [50.21525687290739, 40.286357314539],
        [50.20651775479325, 40.307294780357324],
        [50.17263301401712, 40.32486371383911],
        [50.13826516397674, 40.33621598449935],
        [50.0988391383909, 40.33582321439238],
        [50.066774548733605, 40.33720122868726],
        [50.051924217243965, 40.34009381135286],
        [50.0276142700109, 40.3474324547789],
      ],
    },
  };
  const routesLocalStorageKey = "routes";
  const kmLocalStorageKey = "drivenKms";
  const lastPositionLocalStorageKey = "lastPosition";
  // const greenLabelText =
  //   "Waiting for customs clearance.";
  // const staticPoint = [114.28804649812263, 22.567009917876573];
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [follow, setFollow] = useState([]);
  // eslint-disable-next-line
  const [center, setCenter] = useState([]);
  const [zoomL, setZoomL] = useState(zoomDef);
  const [teams, setTeams] = useState([]);
  const [viewport, setViewport] = useState(defView);
  const [isHidden, setIsHidden] = useState(false);
  const [loading, setLoading] = useState(true);
  const [centerTeam, setCenterTeam] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [drivenKm, setDrivenKm] = useState(0);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  // eslint-disable-next-line
  const [animatedPoint, setAnimatedPoint] = useState(null);
  const [animationRoute, setAnimationRoute] = useState({
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  });
  const handleInfo = (msg) => {
    createMarkers(msg[0], "pos", msg[2]);
  };
  const handleMarkersAnimation = (_animationStatus) => {
    const overlayMarkers = Array.from(
      document.getElementsByClassName("mapboxgl-marker") || []
    );
    overlayMarkers.forEach((marker) => {
      _animationStatus
        ? marker.classList.add("transition-marker")
        : marker.classList.remove("transition-marker");
    });
  };
  const centerFollows = () => {
    if (follow.length > 0) {
      handleMarkersAnimation(false);
      let btns = document.querySelectorAll(".devices-col-btn");
      for (let i = 0; i < teams.length; i++) {
        btns[i].children[0].id = "off";
        for (let j = 0; j < follow.length; j++) {
          if (follow[j][0] === teams[i][1]) {
            btns[i].children[0].id = "on";
          }
        }
      }
      zoomTo(follow, 1000, d3.easeLinear, zoomL, viewport);
    } else {
      handleMarkersAnimation(true);
    }
  };
  const loadCachedData = (cacheTimeout = 1_000 * 60 * 10) => {
    // cacheTimeout in milliseconds
    const now = Date.now();
    const routesData = localStorage.getItem(routesLocalStorageKey);
    const drivenKmsData = localStorage.getItem(kmLocalStorageKey);
    const lastPositionData = localStorage.getItem(lastPositionLocalStorageKey);

    if (routesData) {
      const { data, timestamp } = JSON.parse(routesData);
      if (now - timestamp < cacheTimeout) {
        setRoutes(data);
      }
    }

    if (drivenKmsData) {
      const { km, timestamp } = JSON.parse(drivenKmsData);
      if (now - timestamp < cacheTimeout) {
        setDrivenKm(km);
      }
    }

    if (lastPositionData) {
      const { position, timestamp } = JSON.parse(lastPositionData);
      if (now - timestamp < cacheTimeout) {
        handleInfo(position);
      }
    }
  };
  React.useEffect(() => {
    loadCachedData();
    socket.connect();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    loadHandler();
    window.addEventListener("resize", () => {
      let vw = viewport;
      if (isMobile) {
        vw.height = document.documentElement.clientHeight;
        vw.width = document.documentElement.clientWidth;
      } else {
        vw.height = window.innerHeight;
        vw.width = window.innerWidth;
      }
      setViewport(vw);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    socket.on("connect", () => {
      socket.emit("start-timer");
    });
    socket.on("connect_error", (err) => {
      console.log("Socket connection error. Retrying!");
      if (!socket.connected) socket.connect();
    });
    socket.on("client-response", (res) => {
      let msg = res.data;
      handleInfo(msg);
      const timestamp = Date.now();
      localStorage.setItem(
        lastPositionLocalStorageKey,
        JSON.stringify({ position: res.data, timestamp })
      );
    });
    socket.on("route-response", (res) => {
      setRoutes(res.data);
      console.log(res);
      const timestamp = Date.now();
      localStorage.setItem(
        routesLocalStorageKey,
        JSON.stringify({ data: res.data, timestamp })
      );
      // const main = res.data.filters(item => item.team_number === '01');
      const main = res.data[9];

      let routeLength = 0;
      routeLength = main?.different_car_route?.length;
      Object.entries(main?.done).forEach(([contient, routes]) => {
        routes.forEach((route) => {
          // console.log(routeLength, contient)
          routeLength += route?.length;
        });
      });
      setDrivenKm(routeLength);
      localStorage.setItem(
        kmLocalStorageKey,
        JSON.stringify({ km: routeLength, timestamp })
      );
    });

    let interval = setInterval(() => {
      console.log(socket.connected, "<-- socket connection status");
      if (!socket.connected) {
        socket.connect();
      } else {
        clearInterval(interval);
      }
    }, 1000);
    centerFollows();
    return () => {
      socket.disconnect();
      socket.removeAllListeners();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const interval = setInterval(centerFollows, TIME_BETWEEN_CENTER_FOLLOW);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [follow, viewport.zoom]);

  React.useEffect(() => {
    // follow team 01 when loading

    const followTeam01 = document?.getElementsByName("229LUL2020000001")[0];
    if (!centerTeam && followTeam01) {
      followTeam01?.click();
      // console.log(mapRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);
  // eslint-disable-next-line

  React.useEffect(() => {
    const animateAlongRoute = (
      route,
      duration,
      startTime,
      delayBetweenFrames = 5000
    ) => {
      const routeLine = turf.lineString(route);
      const totalLength = turf.length(routeLine, { units: "kilometers" });

      // eslint-disable-next-line
      const animate = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        if (elapsedTime < duration) {
          const distanceTraveled = totalLength * (elapsedTime / duration);
          const currentPoint = turf.along(routeLine, distanceTraveled, {
            units: "kilometers",
          });
          setAnimatedPoint(currentPoint?.geometry?.coordinates);
          setTimeout(() => {
            requestAnimationFrame(animate); // Request the next frame of the animation
          }, delayBetweenFrames);
        } else {
          setAnimatedPoint(route[route.length - 1]);
        }
      };

      // animate(); // Start the animation
    };
    const { start, end } = routesCoordinates.moroccoToMiami;
    let endRoute = {};
    // Sua to Miami
    if (splitRoute) {
      const auxiliaryPointsNegativeLongitude = [
        [-122.38010466859308, 47.25590811090652],
        [-122.37923983776933, 47.25589085969665],
        [-122.3785885999527, 47.255878298196244],
        [-122.37818264844276, 47.25594897745172],
        [-122.37809206503991, 47.25620992134137],
        [-122.37817668614191, 47.25647086476101],
        [-122.37836344879565, 47.25682626949889],
        [-122.37883053865662, 47.25715789147702],
        [-122.38084520604, 47.25862832380019],
        [-122.38594514538612, 47.26166000585228],
        [-122.4118695288423, 47.27734915550605],
        [-122.4171625115535, 47.279961545068076],
        [-122.42625368369663, 47.28314829002835],
        [-122.43424041284288, 47.28721683199373],
        [-122.4495413169363, 47.29723861381996],
        [-122.46408699027853, 47.31007680964348],
        [-122.4663012487779, 47.31383131036607],
        [-122.4667452606734, 47.31770012844392],
        [-122.46310711268868, 47.320680681174174],
        [-122.45647469437625, 47.32322913476685],
        [-122.41184112890335, 47.33835703843907],
        [-122.38032793556373, 47.34719979763536],
        [-122.36457133889346, 47.353518523575616],
        [-122.35721752301009, 47.36007401589413],
        [-122.35336486578746, 47.365561511830755],
        [-122.3523131354938, 47.37010042622664],
        [-122.35581152876348, 47.37965135190944],
        [-122.4521804620564, 47.540820294361396],
        [-122.47917122107143, 47.657326626289034],
        [-122.47899543005735, 47.67732653177484],
        [-122.46270621603612, 47.6979564831432],
        [-122.44898253946121, 47.72074533183391],
        [-122.43659246160816, 47.76597144909766],
        [-122.4351059179032, 47.860380263414015],
        [-122.43801139558099, 47.88512850476221],
        [-122.45794112251428, 47.90553002360946],
        [-122.58933914815019, 47.97830041460925],
        [-122.6440940007319, 48.03260644924024],
        [-122.66723239040871, 48.10968520006642],
        [-122.7580432935489, 48.17835153396304],
        [-122.91857255078637, 48.225392138730854],
        [-123.22922590062367, 48.247109793464276],
        [-123.4159745849166, 48.250990281236376],
        [-123.5154587622194, 48.22851425020771],
        [-123.61494293952221, 48.23394565886241],
        [-123.83133026123582, 48.259154319427935],
        [-124.09485059701129, 48.323866997949494],
        [-125.3760093424283, 48.59985924622926],
        [-127.60782332897173, 48.956041079708164],
        [-129.8468890266432, 49.30364320567705],
        [-135.93882193925367, 50.19394037215085],
        [-143.43661429715831, 51.432460794607834],
        [-146.43397609614763, 51.93823922014265],
        [-149.6000189814185, 52.29524747650564],
        [-150.7723630938692, 52.395189936629095],
        [-157.642379454052, 52.03634672810736],
        [-162.11974169771153, 51.76944467650719],
        [-166.59710394137107, 51.76106678306408],
        [-171.4061237368677, 51.466944709020424],
        [-175.79717952676052, 50.91124819639188],
        [-179.7910179971966, 50.148528895789866],
      ];
      const auxiliaryPointsPositiveLongitude = [
        [179.768934, 50.148528],
        [175.147625, 48.093456],
        [170.064184, 46.36772],
        [163.283513, 44.839489],
        [162.948774, 44.773953],
        [162.719523, 44.732808],
        [162.35263, 44.650473],
        [161.57304, 44.485739],
        [160.059664, 44.058009],
        [158.58064, 43.609741],
        [157.375392, 42.994033],
        [156.676869, 42.685248],
        [155.989749, 42.208313],
        [155.417789, 41.802794],
        [154.857233, 41.593269],
        [153.781734, 41.345376],
        [152.064064, 41.12452],
        [149.997133, 41.009399],
        [146.201134, 41.151459],
        [144.238403, 41.259475],
        [142.483416, 41.551564],
        [142.044357, 41.645774],
        [141.583371, 41.682634],
        [140.725238, 41.555215],
        [139.885045, 41.174778],
        [139.066779, 40.748117],
        [138.510241, 39.917445],
        [137.535169, 39.158088],
        [135.492017, 38.143328],
        [133.36782, 37.098742],
        [131.291532, 36.248831],
        [130.795437, 36.026057],
        [130.310758, 35.768655],
        [130.025651, 35.59146],
        [129.740543, 35.414266],
        [129.571946, 35.306079],
        [129.399067, 35.206082],
        [129.264719, 35.134045],
        [129.196118, 35.110858],
        [129.1291944691185, 35.117923933163944],
        [129.13294428701744, 35.11663186009939],
        [129.13389707920453, 35.11572654372825],
        [129.1335819608424, 35.11401979894518],
        [129.13230291824226, 35.104057199872955],
        [129.13233223455924, 35.09019046658197],
        [129.13547, 35.062457],
        [129.1343971801276, 35.0504912095345],
        [129.13223442445263, 35.046001034472056],
        [129.12603952178176, 35.04250118401177],
        [129.11364902015129, 35.03622720442707],
        [129.09880166062496, 35.03116020679501],
        [129.01889081308278, 35.016902083892546],
        [128.94979603541825, 35.001144206037594],
        [128.89400120103542, 34.989287382753616],
        [128.85031996715222, 34.98120832914046],
        [128.82665814059123, 34.978678952497035],
        [128.81442251406173, 34.97807744248223],
        [128.803064821199, 34.98063116538342],
        [128.79428820377365, 34.986771658202116],
        [128.78881779950882, 34.9960668763278],
        [128.7870943804978, 35.03616446527636],
        [128.78537096148676, 35.07626205422491],
        [128.78040983648594, 34.936315185282],
        [128.79719544703315, 34.78258446595505],
        [128.79774712254388, 34.68869627239064],
        [128.7861922478881, 34.59446061468176],
        [128.74732139590367, 34.508690504044715],
        [128.67324684596477, 34.3894388516632],
        [128.58912401804844, 34.27314078766578],
        [128.47512100721974, 34.17255521914726],
        [127.852348, 33.526011],
        [127.39213, 33.05803],
        [126.84916539395068, 32.65140083248392],
        [126.2353955942939, 32.26651799758787],
        [125.73907719798348, 32.01952588374536],
        [125.28601246599669, 31.809346492776196],
        [124.25631398790091, 31.356511662034315],
        [124.09523922144305, 31.297077900985137],
        [123.9215635299692, 31.26456030916538],
        [123.61201492207363, 31.231842814055142],
        [123.13572818981696, 31.241811585330616],
        [122.77809923487482, 31.26208175952813],
        [122.44147182162928, 31.23567156226338],
        [122.19865266428249, 31.19230550556545],
        [121.98943597365232, 31.051903162724173],
        [122.1907505407894, 30.924997995799867],
        [122.56507380836572, 30.78746479017878],
        [122.71820884141917, 30.65955692930308],
        [122.75897958862541, 30.57139781105918],
        [122.85088857771703, 30.505253092407138],
        [122.98521830804347, 30.400910501461613],
        [123.04779066093184, 30.27799920592487],
        [123.14423241574279, 29.690995638537892],
        [122.98011035446027, 28.958617795146207],
        [122.82649750771846, 28.239947086855512],
        [122.6415955587331, 27.728718618422054],
        [122.43197842095952, 27.022166063005578],
        [122.0740676505267, 26.38995976673511],
        [121.408458, 25.666604],
        [120.162152, 24.655545],
        [119.662325, 24.093685],
        [119.031389, 23.466561],
        [118.180311, 22.97351],
        [117.473274, 22.650474],
        [116.733305, 22.436989],
        [116.406959, 22.345473],
        [116.207332, 22.321797],
        [115.946709, 22.311653],
        [115.712443, 22.293391],
        [115.486522, 22.28495],
        [115.23653, 22.266867],
        [115.198551, 22.260852],
        [115.148878, 22.263094],
        [115.122313, 22.26513],
        [115.096139, 22.267432],
        [115.044397, 22.269214],
        [114.999794, 22.270048],
        [114.966838, 22.273714],
        [114.938713, 22.280178],
        [114.896624, 22.288467],
        [114.84825, 22.296861],
        [114.814003, 22.301831],
        [114.740291, 22.316984],
        [114.618289, 22.340612],
        [114.553381, 22.350058],
        [114.525109, 22.357416],
        [114.495218, 22.368764],
        [114.469934, 22.390604],
        [114.448425, 22.418425],
        [114.416236, 22.460378],
        [114.387937, 22.508387],
        [114.359732, 22.539466],
        [114.329609, 22.55744],
        [114.315929, 22.563842],
        [114.312741, 22.564854],
        [114.308858, 22.564368],
        [114.305438, 22.563775],
        [114.302249, 22.562326],
        [114.284507, 22.55628],
        [114.282457, 22.554622],
        [114.279711, 22.555105],
        [114.275611, 22.556283],
        [114.270769, 22.560138],
        [114.268655, 22.562246],
        [114.268432, 22.562618],
        [114.268368, 22.562917],
        [114.26883, 22.563297],
        [114.270894, 22.564836],
        [114.271588, 22.56546],
        [114.272003, 22.566109],
        [114.272024, 22.566539],
        [114.271149, 22.567613],
        [114.270135, 22.568596],
        [114.265824, 22.573012],
        [114.260348, 22.568925],
      ];
      if (start[0] > 0 && end[0] < 0 && Math.abs(start[0] - end[0]) > 180) {
        end[0] += 360;
      } else if (
        start[0] < 0 &&
        end[0] > 0 &&
        Math.abs(start[0] - end[0]) > 180
      ) {
        start[0] += 360;
      }

      auxiliaryPointsNegativeLongitude.forEach((point) => {
        if (point[0] < 0) {
          point[0] += 360;
        }
      });
      const suaToHongKongRoute = {
        type: "Feature",
        properties: {
          name: "Route from Sua to Hong Kong",
        },
        geometry: {
          type: "LineString",
          coordinates: [
            start,
            ...auxiliaryPointsNegativeLongitude,
            ...auxiliaryPointsPositiveLongitude,
            end,
          ],
        },
      };

      endRoute = { ...suaToHongKongRoute };
    } else {
      const moroccoToMiamiCoordinates = [
        [-5.438747249241487, 36.12728834440405],
        [-5.435676681390191, 36.12917794133543],
        [-5.433044766088869, 36.13057148992627],
        [-5.432840061639638, 36.132342914832705],
        [-5.432664600614345, 36.134156830583166],
        [-5.433555639216706, 36.141030197280074],
        [-5.432726341242017, 36.146789528810814],
        [-5.430238447318089, 36.15509293685052],
        [-5.424765080685717, 36.15937822282663],
        [-5.416969679723593, 36.161922500558376],
        [-5.40652052524328, 36.160985144684545],
        [-5.39938856266167, 36.156833862524664],
        [-5.395407932433471, 36.1501377828675],
        [-5.39192488093974, 36.14263749500478],
        [-5.392090740534883, 36.13393090444865],
        [-5.38960284661087, 36.124553495744436],
        [-5.3884418294460374, 36.11437104068382],
        [-5.388441828763945, 36.10646532757109],
        [-5.395089650850792, 36.065437746678484],
        [-5.41645405849485, 36.02973869998202],
        [-5.441379200745359, 35.99690432842125],
        [-5.499063101384166, 35.97270183018507],
        [-5.694102709647808, 35.935957188616],
        [-5.872955501003332, 35.93799290232383],
        [-5.960279904001737, 35.94433515586246],
        [-6.373027080609788, 35.91116300968847],
        [-6.797001895485323, 35.89921554840548],
        [-7.421203494646335, 35.92178510453818],
        [-8.056632732074831, 35.96557934556188],
        [-9.058479822080642, 35.934851565804195],
        [-9.778646960472713, 35.95481046300591],
        [-10.409072887659022, 36.0856356003256],
        [-10.976903270041987, 36.27137412335209],
        [-12.004405866733663, 36.684472473101565],
        [-12.612308230355367, 36.99840898061041],
        [-13.444769776479234, 37.132768532603606],
        [-14.479747722212664, 37.1893307500259],
        [-15.578050215898799, 37.21907922195847],
        [-16.944046960344608, 37.20856085510098],
        [-18.778178714584783, 37.236512165534236],
        [-20.468922447960637, 37.21171190683599],
        [-21.963838542290816, 37.217859114572924],
        [-23.498779944748208, 37.22066269391051],
        [-24.575290338266342, 37.18010424434947],
        [-25.46091125759162, 37.120657622966846],
        [-26.215235940875004, 37.07417187963352],
        [-26.992483069539162, 36.93934414900578],
        [-28.028852230960013, 36.92306134608726],
        [-28.839784782490653, 36.93849379814894],
        [-30.461649885551935, 36.9693587022723],
        [-32.43105751069824, 36.9693587022723],
        [-34.40046513584454, 36.9693587022723],
        [-36.08025399258693, 36.9693587022723],
        [-37.760042849329324, 36.9693587022723],
        [-39.439831706071715, 36.9693587022723],
        [-41.119620562814106, 36.9693587022723],
        [-43.34316937475015, 36.815493401551656],
        [-45.56671818668619, 36.66162810083101],
        [-47.19535615744783, 36.49246646942327],
        [-48.79165420535335, 36.26279259202158],
        [-50.83364960257289, 36.03776352092246],
        [-52.897204948363196, 35.764529959329224],
        [-54.132911693553, 35.54398741421221],
        [-55.36861843874279, 35.323444869095205],
        [-57.84003192912239, 34.882359778861186],
        [-59.2436769853178, 34.612624068122166],
        [-60.647322041513206, 34.342888357383146],
        [-63.400712282476306, 33.669840508053525],
        [-64.50126360241106, 33.34607549509256],
        [-65.60181492234581, 33.02231048213161],
        [-67.80291756221531, 32.37478045620969],
        [-68.67177386742685, 32.12886213045462],
        [-69.5406301726384, 31.88294380469955],
        [-71.2783427830615, 31.39110715318941],
        [-72.20962690330862, 31.06280949994136],
        [-73.14091102355574, 30.73451184669331],
        [-74.83099967548223, 29.929602529556774],
        [-75.44885304807713, 29.57575233710801],
        [-76.06670642067203, 29.221902144659246],
        [-76.68455979326694, 28.868051952210482],
        [-77.30241316586184, 28.51420175976172],
        [-77.46174981561836, 28.41101107825059],
        [-77.62108646537487, 28.30782039673946],
        [-77.83678900250305, 28.178712448569293],
        [-78.05249153963125, 28.049604500399127],
        [-78.33506345249921, 27.877221600579787],
        [-78.61763536536716, 27.704838700760448],
        [-78.86918614590098, 27.51849547501547],
        [-79.1207369264348, 27.332152249270493],
        [-79.31013554619815, 27.142930197851847],
        [-79.46137121787405, 26.919811132346794],
        [-79.5489287120021, 26.75633867790239],
        [-79.63648620613014, 26.59286622345799],
        [-79.70575270862831, 26.46354820354555],
        [-79.77501921112648, 26.33423018363311],
        [-79.85698838030422, 26.18528762086958],
        [-79.93895754948194, 26.03634505810605],
        [-80.0653113848514, 25.82856249283772],
        [-80.10726279989623, 25.76650703812837],
        [-80.10748824177969, 25.761349879165756],
        [-80.10814212161952, 25.760106434958157],
        [-80.10990654704216, 25.759196383687467],
        [-80.11278757963123, 25.758876557549936],
        [-80.11576115768513, 25.758640078395395],
        [-80.11854964480838, 25.759070394988484],
        [-80.13035141155815, 25.762042068573482],
        [-80.16450512809364, 25.775986828657267],
      ];
      const moroccoToMiamiRoute = {
        type: "Feature",
        properties: {
          name: "Route from Morocco to Miami",
        },
        geometry: {
          type: "LineString",
          coordinates: moroccoToMiamiCoordinates,
        },
      };

      endRoute = { ...moroccoToMiamiRoute };
    }

    const duration = 2 * 7 * 24 * 60 * 60 * 1000 + 4 * 24 * 60 * 60 * 1000; // Duration of the animation in milliseconds, e.g., 1000ms for 1 second # 2 weeks ### (5 weeks + 4days)
    // const duration = 2 * 60 * 1000; // Duration of the animation in milliseconds, e.g., 1000ms for 1 second # 2 minutes

    const startTime = new Date("2024-05-31T15:00:00").getTime();
    const intervalDuringFrame = 5000; // 5sec
    animateAlongRoute(
      endRoute.geometry.coordinates,
      duration,
      startTime,
      // speed,
      intervalDuringFrame
    );
    setAnimationRoute(endRoute);
  }, []);

  function k_combinations(set, k) {
    var i, j, combs, head, tailcombs;

    if (k > set.length || k <= 0) {
      return [];
    }

    if (k === set.length) {
      return [set];
    }

    if (k === 1) {
      combs = [];
      for (i = 0; i < set.length; i++) {
        combs.push([set[i]]);
      }
      return combs;
    }

    combs = [];
    for (i = 0; i < set.length - k + 1; i++) {
      head = set.slice(i, i + 1);
      tailcombs = k_combinations(set.slice(i + 1), k - 1);
      for (j = 0; j < tailcombs.length; j++) {
        combs.push(head.concat(tailcombs[j]));
      }
    }
    return combs;
  }

  function zoomTo(points, speed, type, zoom, vw) {
    let from, to, distance, midpoint, res, metersPerPixel, zoomLevel;
    let options = { units: "kilometers" };
    switch (points.length) {
      case 0:
        break;

      case 1:
        vw.latitude = points[0][1];
        vw.longitude = points[0][2];
        mapRef.current?.flyTo({
          center: [vw.longitude, vw.latitude],
          duration: speed,
          zoom: centerTeam ? vw.zoom : defView.zoom,
        });
        setViewport(vw);
        break;

      case 2:
        from = turf.point([points[0][1], points[0][2]]);
        to = turf.point([points[1][1], points[1][2]]);

        distance = turf.distance(from, to, options) * 1000; // * 1km = 1000m

        midpoint = turf.midpoint(from, to);

        res = Math.sqrt(window.innerHeight * window.innerWidth);

        metersPerPixel = distance / res;

        zoomLevel = 0;

        for (let z = 0; z < zoomLevels.length; z++) {
          if (z < zoomLevels.length - 1) {
            if (
              zoomLevels[z][1] > metersPerPixel &&
              zoomLevels[z + 1][1] < metersPerPixel
            ) {
              zoomLevel = zoomLevels[z][0] - zoomLevels[z][0] * 0.03;

              break;
            }
          }
        }

        vw.latitude = midpoint.geometry.coordinates[0];
        vw.longitude = midpoint.geometry.coordinates[1];
        zoom === zoomDef
          ? (vw.zoom = zoomLevel)
          : zoom > zoomLevel
          ? (vw.zoom = zoomLevel)
          : (vw.zoom = zoom);
        // vw.transitionDuration = 1000;
        // vw.transitionInterpolator = new FlyToInterpolator();
        // vw.transitionEasing = type;

        mapRef.current?.flyTo({
          center: [vw.longitude, vw.latitude],
          duration: speed,
        });
        setViewport(vw);
        // console.log(vw);
        // console.log(   "meters/pixel:",   metersPerPixel,   "distance in m:",   distance,   "midpoint: ",   midpoint.geometry.coordinates,   "res:",   res );
        break;

      default:
        let coordinates = [];
        for (let a = 0; a < points.length; a++) {
          coordinates.push([points[a][1], points[a][2]]);
        }

        let polygon = turf.points(coordinates);
        let center = turf.center(polygon);

        let distances = [];
        let combi = k_combinations(coordinates, 2);
        // console.log(combi)
        for (let k = 0; k < combi.length; k++) {
          let arr = combi[k];
          distance = turf.distance(arr[0], arr[1], options) * 1000; // * 1km = 1000m
          distances.push(distance);
        }
        let max_distance = Math.max(...distances);

        res = Math.sqrt(window.innerHeight * window.innerWidth);

        metersPerPixel = max_distance / res;
        zoomLevel = 0;

        for (let z = 0; z < zoomLevels.length; z++) {
          if (z < zoomLevels.length - 1) {
            if (
              zoomLevels[z][1] > metersPerPixel &&
              zoomLevels[z + 1][1] < metersPerPixel
            ) {
              zoomLevel = zoomLevels[z][0] - zoomLevels[z][0] * 0.03;
              break;
            }
          }
        }
        vw.latitude = center.geometry.coordinates[0];
        vw.longitude = center.geometry.coordinates[1];

        zoom === zoomDef
          ? (vw.zoom = zoomLevel)
          : zoom > zoomLevel
          ? (vw.zoom = zoomLevel)
          : (vw.zoom = zoom);
        mapRef.current?.flyTo({
          center: [vw.longitude, vw.latitude],
          duration: speed,
        });
        setCenter([vw.latitude, vw.longitude]);
        setViewport(vw);
    }
    setLoading(false);
    setCenterTeam(true);
    setFollow(points);
  }

  const followHandler = (ev) => {
    handleMarkersAnimation(false);

    setTimeout(() => {
      if (follow.length === 0) handleMarkersAnimation(true);
    }, 1000);
    ev.target.id === "off" ? (ev.target.id = "on") : (ev.target.id = "off");
    let text = ev.target.value;
    let devID = "";
    let lat = 0.0;
    let long = 0.0;
    for (let i = 0; i < 3; i++) {
      let comma = text.toString().indexOf(",");
      if (i === 0) {
        devID = text.toString().slice(0, comma);
        text = text.toString().slice(comma + 1, text.length);
      }
      if (i === 1) {
        lat = parseFloat(text.toString().slice(0, comma));
        text = text.toString().slice(comma + 1, text.length);
      }
      if (i === 2) {
        long = parseFloat(text.toString().slice(0));
      }
    }
    let arr = [devID, lat, long];
    if (ev.target.id === "on") {
      setFollow(follow.push(arr));
      zoomTo(follow, 2000, d3.easePolyIn, zoomL, viewport);
    } else {
      let ind;
      for (let x = 0; x < follow.length; x++) {
        if (follow[x][0] === arr[0]) {
          ind = x;
        }
      }
      setZoomL(11);
      setFollow(follow.splice(ind, 1));
      zoomTo(follow, 3000, d3.easePolyIn, zoomL, viewport);
    }
  };

  const createMarkers = (teamsX, status, infos) => {
    setTeams(teamsX);
    const dim = 60;
    const mapboxMarkers = teamsX.map((team, index) => {
      const teamInfo = {
        id: team[1],
        latitude: team[2],
        longitude: team[3],
        createdAt: team[6],
        flag: infos[index][2],
        number: infos[index][1],
        directionAngle: team[5],
        carCategory: infos[index][3],
        abbreviation: infos[index][0],
        displayOnMap: infos[index][4],
      };
      return teamInfo.displayOnMap ? (
        <Marker
          key={teamInfo.id}
          latitude={parseFloat(teamInfo.latitude)}
          longitude={parseFloat(teamInfo.longitude)}
          offsetTop={-dim / 2}
          offsetLeft={-dim / 2}
          rotation={Math.abs(parseFloat(teamInfo.directionAngle) - 180)}
        >
          <img
            src={`/assets/cars/team-${teamInfo.flag}.png`}
            // src={`/assets/team_numbers/${infos[i][1]}.svg`}
            style={{
              height: dim / 2,
              width: dim,
              opacity: 0.95,
              transform: "rotateZ(-90deg)",
            }}
            alt={teamInfo.id}
          ></img>
        </Marker>
      ) : null;
    });

    setMarkers(mapboxMarkers);
  };

  const hideHandle = () => {
    const table = document.querySelector(".table-container");
    const arrowTable = document.querySelector(".arrow-table");
    if (!isHidden) {
      table.style.left = "-600px";
      arrowTable.style.transform = "rotateY(0deg)";
      arrowTable.style.backgroundPosition = "0px center";
    } else {
      table.style.left = "0px";
      arrowTable.style.transform = "rotateY(-180deg)";
      arrowTable.style.backgroundPosition = "25px center";
    }
    setIsHidden(!isHidden);
  };

  const phoneSponsor = () => {
    window.open(PHONE_SPONSOR_LINK, "_blank");
  };
  const FullScreenHandle = () => {
    console.log("click");
    setFullScreenLoading(true);
    var doc = window.document;
    var docEl = doc.documentElement;
    console.log(viewport);
    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl).then(() => {
        setTimeout(() => {
          setFullScreenLoading(false);
        }, 500);
      });
    } else {
      cancelFullScreen.call(doc).then(() => {
        setTimeout(() => {
          setFullScreenLoading(false);
        }, 500);
      });
    }
    if (isMobile) {
      let vw = viewport;
      vw.height = document.documentElement.clientHeight;
      vw.width = document.documentElement.clientWidth;
      setViewport(vw);
    }
  };
  const loadHandler = () => {
    window.scrollTo(0, 9999);

    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 1000);
  };
  const scrollHandle = () => {
    let zl = viewport.zoom;
    if (zl !== zoomL) {
      setZoomL(zl);
    }
  };
  return (
    <Fragment>
      {!loading ? (
        <Fragment>
          {!fullScreenLoading ? (
            <div
              style={{
                height: "100vh",
                width: "100vw",
              }}
            >
              <ReactMapGL
                className={"map"}
                mapboxAccessToken={
                  "pk.eyJ1IjoicmNyaXN0ZWE2ODY4IiwiYSI6ImNsbGFra3ZseTFwOXAzZWxhZTllYTYwem8ifQ.bb_G05UaJNuRzLoolJGeag"
                }
                // mapboxAccessToken={
                //     "pk.eyJ1IjoiZWNvZ3B0ZXN0IiwiYSI6ImNrOGcxdjF4djAwZmozZm84N3gwcTg4OGkifQ.uzL5ggvkW4n_-ShLoydE2g"
                // }
                // mapStyle={localStyle}
                mapStyle={localStyle}
                attributionControl={false}
                initialViewState={viewport}
                onMove={(nextViewport) => {
                  let vw = viewport;
                  vw = { ...nextViewport.viewState };

                  vw.height = document.documentElement.clientHeight;
                  setViewport(vw);
                  setZoomL(vw.zoom);
                }}
                onWheel={scrollHandle}
                onDblClick={FullScreenHandle}
                onMoveStart={() => handleMarkersAnimation(false)}
                onMoveEnd={() => handleMarkersAnimation(true)}
                ref={mapRef}
                projection={"mercator"}
                // projection={"globe"}
              >
                {markers}
                {/* 
                {routes?.map((route) => {
                  let data;
                  if (route["routes_generated"]) {
                    data = (
                      <div key={route["id"]}>
                        {route?.to_be_done?.map((routeInfo, index) => {
                          return (
                            <Source
                              type="geojson"
                              data={routeInfo?.route}
                              key={route["id"] + "-" + index.toString()}
                            >
                              <Layer
                                id={
                                  "to-be-done-route-" +
                                  route["id"] +
                                  "-" +
                                  index.toString()
                                }
                                type="line"
                                paint={{
                                  "line-color": "red",
                                  "line-width": 4,
                                }}
                              />
                            </Source>
                          );
                        })}
                        {Object.entries(route?.done).map(
                          ([contient, direction]) => {
                            return direction?.map((routeInfo, index) => {
                              return (
                                <Source
                                  type="geojson"
                                  data={routeInfo?.route}
                                  key={
                                    route["id"] +
                                    "-" +
                                    contient +
                                    "-" +
                                    index.toString()
                                  }
                                >
                                  <Layer
                                    id={
                                      "done-route" +
                                      route["id"] +
                                      "-" +
                                      contient +
                                      "-" +
                                      index.toString()
                                    }
                                    type="line"
                                    paint={{
                                      "line-color": "#00FF00",
                                      "line-width": 4,
                                    }}
                                  />
                                </Source>
                              );
                            });
                          }
                        )}
                        {!route["one_car"] &&
                          route?.different_car_route?.route && (
                            <Source
                              type="geojson"
                              data={route?.different_car_route?.route}
                            >
                              <Layer
                                id={"second_car_route-" + route["id"]}
                                type="line"
                                paint={{
                                  "line-color": "blue",
                                  "line-width": 4,
                                }}
                              />
                            </Source>
                          )}
                      </div>
                    );
                  }
                  return data;
                })} */}
                {/* <Source id="animationRote" type="geojson" data={animationRoute}>
                  <Layer
                    id="route-animationRote"
                    type="line"
                    layout={{
                      "line-join": "round",
                      "line-cap": "round",
                    }}
                    paint={{
                      "line-color": "blue",
                      "line-width": 5,
                      "line-dasharray": [0.1, 2], // small dash length and longer gap lengt
                    }}
                  />
                </Source>
                <Source id="kurykToBaku" type="geojson" data={kurykToBakuRoute}>
                  <Layer
                    id="route-kurykToBaku"
                    type="line"
                    layout={{
                      "line-join": "round",
                      "line-cap": "round",
                    }}
                    paint={{
                      "line-color": "blue",
                      "line-width": 5,
                      "line-dasharray": [0.1, 2], // small dash length and longer gap lengt
                    }}
                  />
                </Source>
                {animatedPoint && (
                  <Marker
                    longitude={animatedPoint[0] || routesCoordinates.moroccoToMiami.start[0]}
                    latitude={animatedPoint[1] || routesCoordinates.moroccoToMiami.start[1]}
                    // longitude={staticPoint[0]}
                    // latitude={staticPoint[1]}
                  >
                    <img
                      src={`/assets/cargo-ship.png`}

                      style={{
                        height: 60,
                        width: 60,
                        opacity: 0.95,
                        transform: "rotateY(180deg)",     
                        // transform: "rotateZ(-90deg)",

                      }}
                      alt={"cargo ship"}
                    ></img>
                  </Marker>
                )} */}
                <Source type="geojson" data={americaRoute.route}>
                  <Layer
                    id={"america-route"}
                    type="line"
                    paint={{
                      "line-color": "#00FF00",
                      "line-width": 4,
                    }}
                  />
                </Source>
                <Source type="geojson" data={asiaAndEuropeRoute.route}>
                  <Layer
                    id={"asia-europe-route"}
                    type="line"
                    paint={{
                      "line-color": "#00FF00",
                      "line-width": 4,
                    }}
                  />
                </Source>
              </ReactMapGL>
              <div
                className="ranking-phone"
                style={{
                  display: "none",
                }}
              ></div>
              <div
                className="table-container"
                style={{
                  display: "none",
                }}
              >
                <table className={"table-devices"}>
                  <tbody>
                    <tr key={"header"} className={"devices-row"}>
                      <th>{window.innerWidth < 550 ? "flw" : "follow"}</th>
                    </tr>

                    {teams?.map((team, i) => {
                      return (
                        <tr key={team[1]} className={"devices-row"}>
                          <td key={512 * (i + 1)} className={"devices-col-btn"}>
                            <button
                              key={team[1]}
                              name={team[1]}
                              className={"follow-button"}
                              id={"off"}
                              value={[team[1], team[2], team[3]]}
                              onClick={followHandler}
                            ></button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="hide-table">
                  <div className="arrow-table" onClick={hideHandle}></div>
                </div>
                <div className="footer-table"></div>
                <div className="sponsor-phone" onClick={phoneSponsor}></div>
              </div>

              <div
                className="phone-extension"
                style={{
                  display: "none",
                }}
              ></div>
              {/* <motion.div
                initial={{
                  opacity: 0,
                  y: "-200px",
                  x: "-50% ",
                }}
                animate={{ opacity: 1, y: "0px" }}
                transition={{ duration: 1 }}
                className="info-message"
                style={{
                  whiteSpace: "pre-wrap",
                  maxWidth: "90%",
                  textAlign: "center",
                }}
              >
                {greenLabelText}
              </motion.div> */}

              {/* {drivenKm > 0 && (
                <motion.div
                  initial={{
                    opacity: 0,
                    y: "200px",
                    x: "-50%",
                  }}
                  animate={{ opacity: 1, y: "0px" }}
                  transition={{ duration: 0.5 }}
                  className="driven-distance"
                >
                  {Math.ceil(drivenKm / 1000)} KM DRIVEN
                </motion.div>
              )} */}
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </Fragment>
  );
}

export default Map;
