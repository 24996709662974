import React from 'react';
import { motion } from 'framer-motion';

const MobilePopup = ({close}) => {
  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const modal = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
      y: "80%",
      opacity: 1,
      transition: { delay: 0.5 }
    },
  };

  return (
    <motion.div
      style={styles.backdrop}
      variants={backdrop}
      initial="hidden"
      animate="visible"
      exit="hidden"
      onClick={close}
    >
      <motion.div style={styles.modal} variants={modal}>
        <h2 style={{ margin: '8px 0px 10px 0px' }}>Mobile Device Advice</h2>
        <p style={{padding:'0px 6px 15px 6px'}}>Please switch off desktop view for the best experience.</p>
      </motion.div>
    </motion.div>
  );
};

const styles = {
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    width:'80%',
    margin:'0 auto',
    
  },
};

export default MobilePopup;
